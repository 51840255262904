var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { class: _vm.$style.pageTitle }, [_vm._v("お知らせ一覧")]),
    _c("section", { staticClass: "card" }, [
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _vm.loading
            ? _c(
                "div",
                { staticClass: "text-center" },
                [_c("a-spin", { attrs: { tip: "Loading..." } })],
                1
              )
            : _vm._e(),
          !_vm.loading
            ? [
                _vm.notifications.length
                  ? _c("a-table", {
                      attrs: {
                        columns: _vm.columns,
                        "data-source": _vm.notifications,
                        pagination: { pageSize: 100 },
                        rowKey: "id",
                        rowClassName: function(r, i) {
                          return i % 2 === 0
                            ? _vm.$style.stripe0
                            : _vm.$style.stripe1
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "date",
                            fn: function(date) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(_vm._f("formatDate")(date)))
                              ])
                            }
                          },
                          {
                            key: "titleSlot",
                            fn: function(title, record) {
                              return _c(
                                "span",
                                {},
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "notificationShow",
                                          params: { id: record.id }
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(title))]
                                  )
                                ],
                                1
                              )
                            }
                          }
                        ],
                        null,
                        false,
                        277351405
                      )
                    })
                  : _c("p", [
                      _vm._v("\n          お知らせはありません。\n        ")
                    ])
              ]
            : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }