<template>
  <div>
    <h1 :class="$style.pageTitle">お知らせ一覧</h1>
    <section class="card">
      <div class="card-body">
        <div v-if="loading" class="text-center">
          <a-spin tip="Loading..." />
        </div>
        <template v-if="!loading">
          <a-table
            v-if="notifications.length"
            :columns="columns"
            :data-source="notifications"
            :pagination="{ pageSize: 100 }"
            rowKey="id"
            :rowClassName="(r, i) => i % 2 === 0 ? $style.stripe0 : $style.stripe1"
          >
            <span slot="date" slot-scope="date">{{ date | formatDate }}</span>
            <span slot="titleSlot" slot-scope="title, record">
              <router-link :to="{ name: 'notificationShow', params: { id: record.id }}">{{ title }}</router-link>
            </span>
          </a-table>
          <p v-else>
            お知らせはありません。
          </p>
        </template>
      </div>
    </section>
  </div>
</template>
<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
</style>
<script>
import Vue from 'vue'
import moment from 'moment'

export default {
  data() {
    return {
      loading: true,
      notifications: [],

      columns: [
        {
          title: '日付',
          dataIndex: 'date',
          scopedSlots: { customRender: 'date' },
          sorter: (a, b) => {
            return new Date(a.date) - new Date(b.date)
          },
          defaultSortOrder: 'descend',
          width: 120,
        },
        {
          title: 'カテゴリ',
          dataIndex: 'category',
          scopedSlots: { customRender: 'category' },
          sorter: (a, b) => {
            return (a.category || '').localeCompare(b.category)
          },
          width: 160,
        },
        {
          title: 'タイトル',
          dataIndex: 'title',
          scopedSlots: { customRender: 'titleSlot' }, // slot名に'title'は使えない
          sorter: (a, b) => {
            return (a.title || '').localeCompare(b.title)
          },
        },
      ],
    }
  },
  beforeMount() {
    Vue.prototype.$api.send('get', 'notifications')
      .then(response => {
        this.notifications = response
        this.loading = false
      })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': お知らせ一覧の取得に失敗しました。',
        })
        this.loading = false
      })
  },
  filters: {
    formatDate: function (date) {
      if (date) {
        return moment(date).format('YYYY/MM/DD')
      } else {
        return '-'
      }
    },
  },
}
</script>
